
































































moment = require 'moment'
formatter = require 'root/log-formatter'

module.exports =
    name: 'events'

    data: ->
        eventDate: ''
        askDate: yes
        hasEvents: no
        formatedEvents: null
        rawEvents: null

    created: ->
        group = @$route.params.group
        yard = @$route.params.yard

        @$store.commit 'SET_YARD', {yard: group+'/'+yard, kind: @$i18n.t('events.subtitle')}

    methods:
        loadEvents: ->
            group = @$route.params.group
            yard = @$route.params.yard
            @askDate = no
            @hasEvents = no
            @rawEvents = null
            @$q.loading.show {spinner: 'q-spinner-bars', spinnerSize: 250}
            @$http.get "yards/events/#{group}/#{yard}", {params:{date: @eventDate}}
            .then (res) =>

                ops = {
                    status: yes
                    lights: yes
                    curList: yes
                    commands: yes
                    routes: yes
                    autoRoutes: yes
                    login: yes
                    modes: yes
                    blocks: yes
                    csv: no
                }

                @rawEvents = res.data.events.split('\n')

                @formatedEvents = formatter.format @rawEvents, ops

                @hasEvents = yes
                @askDate = yes
                @$q.loading.hide()
            .catch (err) =>
                @$q.notify {
                    type:'negative'
                    position:'left'
                    message: @$i18n.t('events.noLog')
                    onDismiss: => @askDate = yes
                    }
                console.error err
                @$q.loading.hide()
                @eventDate = null

        getCSV: ->

            ops = {
                status: yes
                lights: yes
                curList: yes
                commands: yes
                routes: yes
                autoRoutes: yes
                login: yes
                modes: yes
                blocks: yes
                csv: yes       # <==
            }

            csv = 'category;date;event\n' + formatter.format @rawEvents, ops

            date = moment @eventDate

            blob = new Blob [csv], { type: 'text/csv' }
            anchor = document.createElement 'a'
            anchor.download = "#{@$route.params.group}_#{@$route.params.yard}_#{date.format('YYYY-MM-DD')}.pathfinder.csv"
            anchor.href = (window.webkitURL || window.URL).createObjectURL blob
            anchor.dataset.downloadurl = ['text/csv', anchor.download, anchor.href].join(':')
            anchor.click()
