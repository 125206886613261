###
class of each Note component

this are floating blocks of text that the user can create, edit and move around and are visible in every terminal
###

EventEmitter = require 'eventemitter3'


module.exports = class Note extends EventEmitter

    ###
    create a new instance of a Terminal element
    `name` is this terminal name
    `layers` is an array with layered canvases
    `styles` is the styling object
    `ops` are this terminal parameters
    ###
    constructor: (@name, @layer, @styles, txt) ->
        super arguments...

        @ops = {
            x: 50  # x,y absolute position in yard map
            y: 50
            a: 0  # angle relative to horizon
            # s: 40
            }

        @group = @layer.group()

        box = @group.text(txt).font({size:@styles.note.fntsize}).bbox()
        console.log box
        @group.rect(box.width + 2*@styles.note.margin, box.height + 2*@styles.note.margin)
        .move(-@styles.note.margin,  -@styles.note.margin + box.y)
        .fill(@styles.note.bgcolor)
        .backward()

        # @group.move(100,100).draggable()
