import { render, staticRenderFns } from "./app.vue?vue&type=template&id=4eb630ac&lang=pug&"
import script from "./app.vue?vue&type=script&lang=coffee&"
export * from "./app.vue?vue&type=script&lang=coffee&"
import style0 from "./app.vue?vue&type=style&index=0&lang=stylus&"


/* normalize component */
import normalizer from "!../node_modules/.pnpm/vue-loader@15.9.8_babel-core@6.26.3_css-loader@5.2.7_lodash@4.17.21_pug@3.0.2_vue-template-co_d5y44uiq7aydfjmaarkkatx65a/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports