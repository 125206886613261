




































































module.exports =
    name: 'operators'

    data: ->
        users: null
        name: null
        code: null
        access: null
        # selected: []
        # ops: [
        #     {name:'name', label:@$i18n.t('operators.name'), field:'name', sortable:yes}
        #     {name:'code', label:@$i18n.t('operators.code'), field:'code'}
        #     {name:'access', label:@$i18n.t('operators.access'), field:'access'}
        # ]
        name: ''
        code: ''
        canCutlist: no  # C
        canUpgrade: no  # U
        canAuto: no  # L
        canBlock: no  # B
        canManage: no  # A
        canManual: no  # M
        canEdit: no  # E
        canSeeLog: no  # S

    created: ->
        group = @$route.params.group
        yard = @$route.params.yard

        @$store.commit 'SET_YARD', {yard: group+'/'+yard, kind: @$i18n.t('operators.subtitle')}

        @$http.get "yards/configuration/#{group}/#{yard}" #, {params:{raw: '1'}}
        .then (res) =>
            @users = res.data.users or []
        .catch (err) =>
            console.error err

    methods:
        add: ->
            @name = ''
            @code = ''
            @canCutlist = yes
            @canUpgrade = no
            @canAuto = yes
            @canBlock = no
            @canManage = no
            @canManual = yes
            @canEdit = no
            @canSeeLog = no
            @$refs.modalAdd.show()

        cancel: -> @$refs.modalAdd.hide()

        remove: (idx, user) ->
            @$q.dialog {
                title: @$i18n.t('operators.rmTitle')
                message: @$i18n.t('operators.rmMesg', user)
                ok: {label: @$i18n.t('operators.rmYes'), color: 'negative'}
                cancel: @$i18n.t('operators.rmNo')
            }
            .then =>
                @users.splice idx, 1

        append: ->
            name = @name.replace(/^\s*/, '').replace(/\s*$/, '').replace(/\s+/g, ' ').replace(/[^a-z0-9\s]/g, '')
            if name.length < 3
                return @$q.notify {type:'negative', position:'top-right', message: @$i18n.t('operators.errTooShort')}

            code = @code.replace /\D/g, ''
            unless code.length == 6
                return @$q.notify {type:'negative', position:'top-right', message: @$i18n.t('operators.err6Digits')}

            if @users.find (u) -> u.code == code
                return @$q.notify {type:'negative', position:'top-right', message: @$i18n.t('operators.errInUse')}

            access = ''
            access += 'C' if @canCutlist
            access += 'U' if @canUpgrade
            access += 'L' if @canAuto
            access += 'B' if @canBlock
            access += 'A' if @canManage
            access += 'M' if @canManual
            access += 'E' if @canEdit
            access += 'S' if @canSeeLog

            unless access.length
                return @$q.notify {type:'negative', position:'top-right', message: @$i18n.t('operators.errNoAllowed')}

            @users.push { name, code, access }

            @$refs.modalAdd.hide()

            @$q.notify {type:'positive', position:'top-right', message: @$i18n.t('operators.added')}

        save: ->
            @$http.post "yards/operators/#{@$route.params.group}/#{@$route.params.yard}", @users, {}
            # @$http.post 'yards/operators', @editor.getValue(), {headers:{"Content-Type":"text/yaml"}}
            .then => @$q.notify {type:'positive', position:'top-right', message:@$i18n.t('operators.done')}
            .catch (err) => @$q.notify {type:'negative', position:'top-right', message:@$i18n.t('operators.failed')}
