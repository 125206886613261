###
class of each Asset image
###

EventEmitter = require 'eventemitter3'


module.exports = class Asset extends EventEmitter

    ###
    create a new instance of a Asset element
    `name` is this asset name
    `layers` is an array with layered canvases
    `ops` are this asset parameters
    ###
    constructor: (@name, @layers, @ops) ->
        super arguments...

        level = @ops.order
        level = 0 if level < 0
        level = 3 if level > 3

        @group = @layers[level].group()

        @group.svg(@ops.content)
        .scale(@ops.zoom || 1)
        .translate(@ops.x, @ops.y)
        .rotate(@ops.a || 0)

        @group.click => @emit 'click', @name, @ops if @ops.dev

        @state = {}

    ###
    sets this asset state
    `state` is a object with properties:
    - TODO....
    ###
    setState: (state) ->
        @state = Object.assign {}, @state, state
