















































































































































































































# {Bar} = require 'vue-chartjs'
moment = require 'moment'

module.exports =
    name: 'dashboard'

    data: ->
        yards: []

    computed:
        auth: -> @$store.state.auth

    # components:
        # barChart:
        #     extends: Bar
        #     name: 'vue-chartjs-bar'
        #     props: ['data', 'options']
        #     mounted: -> @renderChart @data, @options

    created: ->
        @$store.commit 'SET_YARD', null
        @$http.get 'yards'
        .then (r) =>
            @yards = r.data
            # for y in r.data
            #     y._g1 =
            #         ops:
            #             title: {display:yes, text: @$t('dash.g1title')}
            #             responsive: yes
            #             maintainAspectRatio: no
            #             scales:
            #                 xAxes: [ {ticks: {autoSkip: no, stacked:yes}} ]
            #                 yAxes: [ {ticks: {beginAtZero: yes, stacked:yes}} ]
            #         data:
            #             labels: [],
            #             datasets: [
            #                 {label: @$t('dash.g1data1'), data: [], backgroundColor: '#f44c', stack:'1'}
            #                 {label: @$t('dash.g1data2'), data: [], backgroundColor: '#32f8', stack:'1'}
            #                 # {label: @$t('dash.g1data1'), data: [], borderColor: '#f00', backgroundColor: '#f003'}
            #                 # {label: @$t('dash.g1data2'), data: [], fill:no, spanGaps:yes, borderColor: '#00f'}
            #             ]

            #     tmp = []
            #     tmp.push {sw:k, moves:v, cmds:y._metrics.commands[k]} for k, v of y._metrics.moves
            #     tmp = tmp.sort (a, b) -> b.moves - a.moves
            #     for i in tmp
            #         y._g1.data.labels.push i.sw
            #         y._g1.data.datasets[0].data.push i.moves-i.cmds
            #         y._g1.data.datasets[1].data.push i.cmds

            #     y._g2 =
            #         ops:
            #             title: {display:yes, text: @$t('dash.g2title')}
            #             maintainAspectRatio: no
            #             scales:
            #                 xAxes: [ {ticks: {autoSkip: no}} ]
            #                 yAxes: [ {ticks: {beginAtZero: yes}} ]
            #         data:
            #             labels: [],
            #             datasets: [
            #                 # {label: @$t('dash.g2data1'), data: [], borderColor: '#f84', backgroundColor: '#f843'}
            #                 {label: @$t('dash.g2data1'), data: [], backgroundColor: '#f848'}
            #             ]

            #     tmp = []
            #     tmp.push {sw:k, rotes:v} for k, v of y._metrics.destinations
            #     tmp = tmp.sort (a, b) -> b.rotes - a.rotes
            #     # tmp = tmp.sort (a, b) -> a.sw.localeCompare b.sw
            #     for i in tmp
            #         y._g2.data.labels.push i.sw
            #         y._g2.data.datasets[0].data.push i.rotes

            #     y._g3 =
            #         ops:
            #             title: {display:yes, text: 'What else?'}
            #             maintainAspectRatio: no
            #             scales:
            #                 xAxes: [ {ticks: {autoSkip: no}} ]
            #                 yAxes: [ {ticks: {beginAtZero: yes}} ]
            #         data:
            #             labels: [],
            #             datasets: [
            #                 # {label: 'destinations', data: [], borderColor: '#f84', backgroundColor: '#f843'}
            #             ]

        .catch (err) ->
            console.error err

    methods:
        download: (yard) ->
            @$http.get "yards/configuration/#{yard.group}/#{yard.name}", {params:{export: '1'}}
            .then (res) =>
                # @yardCfg = res.data
                # @editor.setValue @yardCfg, -1 if @editor
            .catch (err) =>
                console.error err

        niceDate: (d, relative) ->
            return '---' unless d
            return moment().from d, true if relative
            moment(d).format 'llll'

 