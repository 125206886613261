













































module.exports =
    name: 'welcome'

    data: ->
        user: ''
        passwd: ''
        busy: no

    computed:
        go: -> !!@user and !!@passwd

    created: ->
        @user = @$store.state.lastLogin

    methods:
        login: ->
            return unless @user and @passwd
            console.log 'tentando...'
            @busy = yes
            @$http.post 'auth/', {user: @user, passwd: @passwd}
            .then (res) =>
                console.log res
                @busy = no
                @$store.commit 'LAST_LOGIN', @user
                @$store.commit 'SET_AUTH', res.data
                @$router.replace {name: 'dashboard'}
            .catch (err) =>
                console.error err
                @busy = no
                @$q.notify {type:'negative', position:'top-right', message:@$i18n.t('welcome.badLogin')}

