













































# import iconSet from 'quasar/icon-set/mdi-v5.js'   # must be used to replace the standard material-icons


module.exports =
    name: 'app'

    # data: ->
    #    showLeft: yes

    computed:
        auth: -> @$store.state.auth
        activeYard: -> @$store.state.activeYard

    # components:
    created: ->
        @$http.get 'auth/'
        .then (res) =>
            console.log res
            @$store.commit 'SET_AUTH', res.data
            @$router.replace {name: 'dashboard'}
        .catch (err) =>
            console.error err
            # @$q.notify {type:'negative', position:'top', message:@$i18n.t('welcome.badLogin')}

    mounted: ->
        # @$q.dark.set yes
        # @$q.iconSet.set iconSet

    # beforeDestroy: ->
    methods:
        logout: ->
            @$http.delete 'auth'
            @$store.commit 'SET_YARD', null
            @$store.commit 'SET_AUTH', null

