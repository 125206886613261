###
class of each text label
###

EventEmitter = require 'eventemitter3'


module.exports = class Label extends EventEmitter

    ###
    create a new instance of a label element
    `name` is this label name
    `layers` is an array with layered canvases
    `styles` is the styling object
    `ops` are this label parameters
    ###
    constructor: (@name, @layers, @styles, @ops) ->
        super arguments...

        order = @ops.order
        order = 0 if order < 0
        order = 3 if order > 3

        g = @layers[order].group()

        t = g.text(@ops.text)
        .translate(@ops.x, @ops.y)
        .font({size:@ops.fs, fill:@ops.fg, stroke:'#999', 'stroke-width':.5})

        box = g.bbox t

        g.rect(box.w + @ops.p, box.h + @ops.p)
        .attr({fill:@ops.bg})
        .center(box.cx, box.cy)
        .back()

        g.rotate(@ops.a)

        g.click => @emit 'click', @name, @ops if @ops.dev

        @state = {}

    ###
    sets this label state
    `state` is a object with properties:
    - TODO....
    ###
    setState: (state) ->
        @state = Object.assign {}, @state, state
