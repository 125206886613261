import Vue from 'vue'
import Vuex from 'vuex'
# writeFile = require 'write-file-atomic'
# fs = require 'fs'
# {join} = require 'path'
{merge} = require 'lodash'

Vue.use Vuex

# storeFile = join electron.remote.app.getPath('userData'), 'store.json'

# console.log 'Store file:', storeFile

savedItems = {
    LAST_LOGIN: ['LAST_LOGIN', 'lastLogin']
    }


module.exports = new Vuex.Store {
    state: {
        cfg: require './config'
        auth: null
        lastLogin: ''
        activeYard: null
    }

    actions: {
    }

    mutations: {
        SET_AUTH: (state, user) -> state.auth = user
        LAST_LOGIN: (state, user) -> state.lastLogin = user
        SET_YARD: (state, yard) -> state.activeYard = yard
    }

    getters: {
    }

    # modules: {}

    strict: process.env.NODE_ENV != 'production'

    plugins: [
        (store) ->
            try
                tmp = JSON.parse window.localStorage.store
                # tmp = JSON.parse fs.readFileSync storeFile

                store.commit k, v for k, v of tmp

            saveList = Object.keys savedItems

            store.subscribe (mutation, state) ->

                if mutation.type in saveList
                    tmp = {}
                    tmp[m] = state[sk] for k, [m, sk] of savedItems

                    # console.log 'saving':tmp

                    window.localStorage.store = JSON.stringify tmp
                    # writeFile storeFile, JSON.stringify(tmp), {}, (e) -> console.log e if e
    ]
}
