###
class of each Switch
###

EventEmitter = require 'eventemitter3'


module.exports = class Switch extends EventEmitter

    ###
    create a new instance of a Switch
    `name` is the name of that switch
    `layers` is an array with layered canvases
    `styles` is the styling object
    `ops` are the switch graphical parameters
    ###
    constructor: (@name, @layers, @styles, @ops) ->
        super arguments...

        @rails = {}

        # console.log @ops

        arad = @ops.a*3.1415/180
        rarad = @ops.ra*3.1415/180

        @entry = [@ops.x+Math.cos(arad+3.1415)*@ops.es, @ops.y-Math.sin(arad+3.1415)*@ops.es]
        @norm = [@ops.x+Math.cos(arad)*@ops.ns, @ops.y-Math.sin(arad)*@ops.ns]
        if not @ops.derail
            @rev = [@ops.x+Math.cos(arad+rarad)*@ops.rs, @ops.y-Math.sin(arad+rarad)*@ops.rs]

        @group = @layers[2].group()

        if @ops.manual
            @entryLine = @group.line(@ops.x, @ops.y, @entry[0], @entry[1])
            .attr({'stroke-width':@styles.railWidth, 'stroke-linecap':'round', stroke:@styles.railDarkColor})
            @normLine = @group.line(@ops.x, @ops.y, @norm[0], @norm[1])
            .attr({'stroke-width':@styles.railWidth, 'stroke-linecap':'round', stroke:@styles.railDarkColor})
            @revLine = @group.line(@ops.x, @ops.y, @rev[0], @rev[1])
            .attr({'stroke-width':@styles.railWidth, 'stroke-linecap':'round', stroke:@styles.railDarkColor})

        else if @ops.derail
            @d1join = [@ops.x+Math.cos(arad+3.1415)*@ops.rs, @ops.y-Math.sin(arad+3.1415)*@ops.rs]
            @d2join = [@ops.x+Math.cos(arad)*@ops.rs, @ops.y-Math.sin(arad)*@ops.rs]

            @d1open = [@ops.x+Math.cos(arad+rarad+3.1415/2)*@ops.rzs, @ops.y-Math.sin(arad+rarad+3.1415/2)*@ops.rzs]
            @d2open = [@ops.x+Math.cos(arad+rarad-3.1415/2)*@ops.rzs, @ops.y-Math.sin(arad+rarad-3.1415/2)*@ops.rzs]

            #! the small line that connect rail to the first side of derail
            @entryLine = @group.group()
            @entryLine.line(@d1join[0], @d1join[1], @entry[0], @entry[1])
            .attr({'stroke-width':@styles.railWidth, 'stroke-linecap':'round', stroke:@styles.railColor})

            #! this is a small line to connect the other side of the entry (normal side)
            @entryLine.line(@d2join[0], @d2join[1], @norm[0], @norm[1])
            .attr({'stroke-width':@styles.railWidth, 'stroke-linecap':'round', stroke:@styles.railColor})

            #! normal is a straight line connecting entry with normal exit
            @normLine = @group.line(@d1join[0], @d1join[1], @d2join[0], @d2join[1])
            .attr({'stroke-width':@styles.railWidth, 'stroke-linecap':'round', stroke:@styles.normColor})

            #! reverse is a group with two OPEN lines
            @revLine = @group.group()

            @revLine.line(@d1join[0], @d1join[1], @d1open[0], @d1open[1])
            .attr({'stroke-width':@styles.railWidth, 'stroke-linecap':'round', stroke:@styles.revColor})
            @revLine.line(@d2join[0], @d2join[1], @d2open[0], @d2open[1])
            .attr({'stroke-width':@styles.railWidth, 'stroke-linecap':'round', stroke:@styles.revColor})

            @alert = @layers[1].circle(@ops.bs).center(@ops.x, @ops.y).attr({'fill':'#449'}).rotate(45).back()

            @lockRing = @layers[1].circle(@ops.bs+18).center(@ops.x, @ops.y).attr({stroke:'#28f', 'stroke-width':5}).back()

        else  # normal switch (including xover)
            @entryLine = @group.line(@ops.x, @ops.y, @entry[0], @entry[1])
            .attr({'stroke-width':@styles.railWidth, 'stroke-linecap':'round', stroke:@styles.railColor})
            @normLine = @group.line(@ops.x, @ops.y, @norm[0], @norm[1])
            .attr({'stroke-width':@styles.railWidth, 'stroke-linecap':'round', stroke:@styles.normColor})
            @revLine = @group.line(@ops.x, @ops.y, @rev[0], @rev[1])
            .attr({'stroke-width':@styles.railWidth, 'stroke-linecap':'round', stroke:@styles.revColor})

            @alert = @layers[1].circle(@ops.bs).center(@ops.x, @ops.y).attr({'fill':'#449'}).rotate(45).back()

            @lockRing = @layers[1].circle(@ops.bs+18).center(@ops.x, @ops.y).attr({stroke:'#28f', 'stroke-width':5}).back()


        # @group.text(if @ops.remote then @ops.label[1...] else @ops.label)
        @group.text(@ops.label)
        .translate(@ops.x+@ops.lx, @ops.y+@ops.ly)
        .font({size:@styles.idFontSize, fill:'#fff', stroke:'#999'})
        .rotate(@ops.la)

        # @group.scale(@styles.switch.zoom)

        if not @ops.manual or @ops.dev
            @click = @layers[3].circle(@styles.switch.clickSize).center(@ops.x, @ops.y).attr(@styles.clickAreaColor)

            evType = 'switch'
            evType = 'manual' if @ops.manual
            evType = 'derail' if @ops.derail
            @click.click => @emit 'click', evType, @name, @ops

        # setTimeout => @setState({pos:'O', occ:no, ooc:no, mow:no, online:yes, fault:no})
        # @setState({pos:'O', occ:no, ooc:no, mow:no, online:yes, fault:no})
        @state = {svz:{}}

    ###
    show some highlight on this object.
    it can be `positive` or `negative` to indicate if the command was accepted or not
    ###
    highlight: (type) ->
        @click.attr {'fill-opacity':1}
        setTimeout =>
            @click.attr {'fill-opacity':.05}
        , 150

    ###
    saves a reference to the rail connected to `port`
    port should be one of `E`, `N` or `R`
    dark switch return -1 to indicate it will not activate any indication
    normal switch return positive to enable zone indication
    ###
    setConnection: (id, rail, port) ->
        # @rails[port] = {rail, id}
        switch port
            when 'E'
                @rails.E = {rail, id}
                return -1 if @ops.manual
                return @ops.ezs
            when 'N'
                @rails.N = {rail, id}
                return -1 if @ops.manual
                return @ops.nzs
            when 'R'
                @rails.R = {rail, id}
                return -1 if @ops.manual
                return @ops.rzs

    ###
    sets this switch state
    `state` is an object with properties:
    - `pos`
    - `occ`
    - `ooc`
    - `mow`
    - `offline`
    - `fault`
    ###
    setState: (state) ->
        svz = Object.assign @state.svz, (state.svz || {})
        @state = Object.assign {}, @state, state, {svz}

        # console.log @name, JSON.stringify(@state.svz) if state.svz and @name == "801"

        # console.log  @name
        # @entryLine.find('line').attr({stroke: 'blue'})
        # @normLine.attr({stroke: 'blue'})
        # @revLine.find('line').attr({stroke: 'yellow'})
        # @alert.attr({opacity:0})
        # return

        return if @ops.manual

        @lockRing.hide()

        if @state.dev
            if @ops.detail
                @normLine.attr({opacity:1, stroke:@styles.normColor})
                @revLine.find('line').attr({opacity:1, stroke:@styles.revColor})
                @rails.E.rail.setZone @rails.E.id, yes
                @rails.N.rail.setZone @rails.N.id, yes
                # @rails.R.rail.setZone @rails.R.id, yes
            else
                @normLine.attr({opacity:1, stroke:@styles.normColor})
                @revLine.attr({opacity:1, stroke:@styles.revColor})
                @rails.E.rail.setZone @rails.E.id, yes
                @rails.N.rail.setZone @rails.N.id, yes
                @rails.R.rail.setZone @rails.R.id, yes
            return

        if @ops.derail
            @entryLine.find('line').attr({stroke:@styles.railColor})
            @normLine.attr({opacity:.1, stroke:@styles.normColor})
            @revLine.find('line').attr({opacity:.1, stroke:@styles.revColor})
        else
            @entryLine.attr({stroke:@styles.railColor})
            @normLine.attr({opacity:.1, stroke:@styles.normColor})
            @revLine.attr({opacity:.1, stroke:@styles.revColor})
        # @alert.stop().attr({opacity:0})
        @alert.attr({opacity:0})
        @rails.E.rail.setZone @rails.E.id, no
        @rails.N.rail.setZone @rails.N.id, no
        @rails.E.rail.setState @rails.E.id, {routed: no}
        @rails.N.rail.setState @rails.N.id, {routed: no}
        unless @ops.derail
            @rails.R.rail.setZone @rails.R.id, no
            @rails.R.rail.setState @rails.R.id, {routed: no}

        # if @state.fullMow
        #     @entryLine.attr({stroke: '#28f'})
        #     @normLine.attr({stroke: '#28f'})
        #     @revLine.attr({stroke: '#28f'})

        if @state.offline
            @alert.attr({opacity:1, fill:@styles.switch.offline})
            return

        if @state.pos == 'N'
            @normLine.attr({opacity:1})

        if @state.pos == 'R'
            if @ops.derail
                @revLine.find('line').attr({opacity:1})
            else
                @revLine.attr({opacity:1})

#.....

        # if @state.ooc and (@state.mow or @state.blocks)
        #     @alert.attr(opacity:1, fill:@styles.switch.ooc_mow)
        #     # console.log '99999999999999999999999999999999', @styles.switch
        # else
        if @state.ooc
            @alert.attr({opacity:1, fill:@styles.switch.ooc})
            # @alert.attr({fill:'orange'}).animate(600, '-').attr({opacity:1}).loop(null, true)

        if !@ops.hideMOW
            if @state.mow or @state.blocks
                @alert.attr({opacity:1, fill:@styles.switch.mow})
                if @state.blocks
                    @lockRing.show()

        if @state.routed
            # console.log @name, @state
            if @state.pos == 'N' and \
                ((@state.side == 'M' and @ops.xover) or \
                (@state.side == 'S' and @ops.remote) or \
                (not (@ops.xover or @ops.remote)))

                @entryLine.attr({stroke: @styles.routeColor})
                @rails.E.rail.setState @rails.E.id, {routed: true}
                @normLine.attr({stroke: @styles.routeColor})
                @rails.N.rail.setState @rails.N.id, {routed: true}

            if @state.pos == 'R'
                @revLine.attr({stroke: @styles.routeColor})
                unless @ops.derail
                    @entryLine.attr({stroke: @styles.routeColor})
                    @rails.E.rail.setState @rails.E.id, {routed: true}
                    @rails.R.rail.setState @rails.R.id, {routed: true}

        if @ops.derail

            # TODO
            # TODO we need to review this for virtual zone operation
            # TODO

            if @state.occu
                @entryLine.find('line').attr({stroke: @styles.occuColor})
                @normLine.attr({stroke: @styles.occuColor})
                @revLine.find('line').attr({stroke: @styles.occuColor})
                @rails.E.rail.setZone @rails.E.id, yes
                # if @state.pos == 'N'
                @rails.N.rail.setZone @rails.N.id, yes
                # if @state.pos == 'R'
                #     @rails.R.rail.setZone @rails.R.id, yes
        else

            self = no

            if @ops.xover and @state.occu1 \
                or @ops.remote and @state.occu2 \
                or !@ops.xover and !@ops.remote and @state.occu

                self = yes
                @entryLine.attr({stroke: @styles.occuColor})
                @normLine.attr({stroke: @styles.occuColor})
                @revLine.attr({stroke: @styles.occuColor})

            if self \
                or @state.svz.E \
                or @state.svz.A and @ops.xover \
                or @state.svz.D and @ops.remote
                @rails.E.rail.setZone @rails.E.id, yes

            if self and @state.pos == 'N' \
                or @state.svz.N \
                or @state.svz.B and @ops.xover \
                or @state.svz.C and @ops.remote
                @rails.N.rail.setZone @rails.N.id, yes

            if (self and @state.pos == 'R') or (@state.svz.R)
                @rails.R.rail.setZone @rails.R.id, yes



        if @state.fault
            @alert.attr({opacity:1, fill:@styles.switch.fault})
            # return


        # FIXME... this only works for a classification yard.... the propagation of the signal is
        # unidirectional in here... thats bad


        # if @state.mow
        #     if @state.pos == 'N'
        #         @rails.R.rail.setState @rails.R.id, {mow: true}
        #     if @state.pos == 'R'
        #         @rails.N.rail.setState @rails.N.id, {mow: true}
        # else
        #     @rails.N.rail.setState {mow: no}
        #     @rails.R.rail.setState {mow: no}
