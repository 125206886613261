/*
class of each Editor Item
*/

const EventEmitter = require("eventemitter3");

module.exports = class Editor extends EventEmitter {
    /*
    create a new instance of a editor element
    `name` is this label name
    `layers` is an array with layered canvases
    `ops` are this label parameters
    */
    constructor(name, layers, styles, ops) {
        super(...arguments);

        this.name = name;
        this.layers = layers;
        this.styles = styles;
        this.ops = ops;

        const base = this.layers[3].group();

        console.log(this);

        base.rect(this.ops.w, this.ops.h)
            .radius(8)
            .css({ "text-anchor": "middle", "dominant-baseline": "middle" })
            .center(this.ops.x, this.ops.y)
            .attr({ fill: this.ops.bg, stroke: "white", "stroke-width": 1.2 });

        this.content = base
            .text(this.ops.text || "-")
            .attr({ fill: this.ops.fg })
            .font({ size: this.ops.fs })
            .css({ "text-anchor": "middle", "dominant-baseline": "middle" })
            .center(this.ops.x, this.ops.y);

        base.rotate(this.ops.a);

        base.click(() => this.emit("click", this.name, this.ops));

        this.state = {};

        this.setState({ text: this.ops.text });
    }

    /*
    show some highlight on this object.
    it can be `positive` or `negative` to indicate if the command was accepted or not
    */
    highlight(type) {
        // if (type == "negative") this.btnBody.attr(this.styles.control.negative);
        // if (type == "positive") this.btnBody.attr(this.styles.control.positive);
        // setTimeout(() => {
        //     this.setState();
        // }, 250);
    }

    /*
    sets this control state
    `state` is a object with properties:
    - TODO....
    */
    setState(state) {
        this.state = Object.assign({}, this.state, state);

        this.content.text(this.state.text || "-");
    }
};
