/*
class of each Route-List control Item
*/

const EventEmitter = require("eventemitter3");

module.exports = class ControlRouteList extends EventEmitter {
    /*
    create a new instance of a route-list element
    `name` is this label name
    `layers` is an array with layered canvases
    `ops` are this label parameters
    */
    constructor(name, layers, styles, ops) {
        super(...arguments);

        this.name = name;
        this.layers = layers;
        this.styles = styles;
        this.ops = ops;

        this.state = {};

        this.done = false;

        this.setState({});
    }

    /*
    show some highlight on this object.
    it can be `positive` or `negative` to indicate if the command was accepted or not
    */
    highlight(type) {
        // if (type == "negative") this.btnBody.attr(this.styles.control.negative);
        // if (type == "positive") this.btnBody.attr(this.styles.control.positive);
        // setTimeout(() => {
        //     this.setState();
        // }, 250);
    }

    /*
    sets this control state
    `state` is a object with properties:
    - TODO....
    */
    setState(state) {
        this.state = Object.assign({}, this.state, state);

        if (!this.state.list || !this.state.list.length) return;

        console.log(this.state);

        if (this.done) return;

        this.done = true;

        this.base = this.layers[3].group();

        const bw = 500;
        const bh = 100;
        const gap = 30;
        const fs = 50;

        this.base
            .rect(bw + 2 * gap, gap + (bh + gap) * this.state.list.length)
            .radius(8)
            .move(this.ops.x - gap, this.ops.y - gap)
            .attr({ "stroke-width": 1.2 })
            .attr({ fill: "#445", stroke: "white" })
            .back();

        let y = this.ops.y;

        for (const r of this.state.list) {
            console.log(r);

            const b = this.base.group();

            b.rect(bw, bh)
                .radius(8)
                .move(this.ops.x, y)
                .attr({ fill: "#44b", stroke: "white", "stroke-width": 1.2 });

            b.text(r.name)
                .attr({ fill: "white" })
                .font({ size: fs })
                .css({ "text-anchor": "middle", "dominant-baseline": "middle" })
                .center(this.ops.x + bw / 2, y + bh / 2);

            b.click(() => this.emit("click", this.name, r.paths));

            y += bh + gap;
        }
    }
};
