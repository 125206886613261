###
class of each Terminal component
###

EventEmitter = require 'eventemitter3'


module.exports = class Terminal extends EventEmitter

    ###
    create a new instance of a Terminal element
    `name` is this terminal name
    `layers` is an array with layered canvases
    `styles` is the styling object
    `ops` are this terminal parameters
    ###
    constructor: (@name, @layers, @styles, @ops) ->
        super arguments...

        @state = {}

        setTimeout => @setState {blocked: no}

        @group = @layers[2].group()
        switch @ops.style
            when 'right'
                @body = @group.path("M#{20 - 10 + @ops.w/2} 0 l-20 #{@ops.h/2} h-#{@ops.w} v-#{@ops.h} h#{@ops.w} z")
            when 'left'
                @body = @group.path("M-#{20 - 10 + @ops.w/2} 0 l20 #{@ops.h/2} h#{@ops.w} v-#{@ops.h} h-#{@ops.w} z")
            when 'bidir'
                @body = @group.path("M-#{20 + @ops.w/2} 0 l20 #{@ops.h/2} h#{@ops.w} l20 -#{@ops.h/2} l-20 -#{@ops.h/2} h-#{@ops.w} z")
            when 'circle'
                @body = @group.ellipse(@ops.w, @ops.h).translate(-@ops.w/2, -@ops.h/2)
                @state.dead = yes
            # when 'box'
            else
                @body = @group.path("M-#{@ops.w/2} #{@ops.h/2} h#{@ops.w} v-#{@ops.h} h-#{@ops.w} z")
                @state.dead = yes

        @body.center(@ops.x, @ops.y)

        txt = @group.text(@ops.label.replace('\\n', '\n'))
            .attr({ "text-anchor": "middle", "dominant-baseline": "middle", "font-weight": "bold" })
            .font({size:@ops.fs})
            # # .move(@ops.lx, @ops.ly)
            .center(@ops.x+0, @ops.y)

        @body.attr(@styles.terminal.normal)
        # @body.attr({'stroke': 'red'}).fill('none')


        txt.rotate(180) if @ops.a > 90 or @ops.a < -90

        @group
        # .scale(@styles.terminal.zoom)
        .rotate(@ops.a)
        # .translate(@ops.x, @ops.y)
        .click => @emit 'click', @name, @ops

    ###
    show some highlight on this object.
    it can be `positive` or `negative` to indicate if the command was accepted or not
    ###
    highlight: (type) ->
        if type == 'negative'
            @body.attr @styles.terminal.negative
        if type == 'positive'
            @body.attr @styles.terminal.positive
        setTimeout =>
            @setState()
        , 250

    ###
    save a reference to the rail connected to this terminal
    terminals are neutral so return 0
    ###
    setConnection: (connID, @rail) ->
        # return 1 if @ops.style == 'bidir'
        # return 1 if @ops.style == 'right' and connID == 1
        # return 1 if @ops.style == 'left' and connID == 2
        return 0

    ###
    sets this terminal state
    `state` is a object with properties:
    - `targeted`
    - `available`
    - `blocked`
    - `source`
    - `hasIssue` - this only apply for targeted terminal that has issued switches in its way
    ###
    setState: (state) ->
        @state = Object.assign {}, @state, state

        @body.attr(@styles.terminal.normal)

        if @state.source
            @body.attr(@styles.terminal.source)
            return

        if @state.targeted
            @body.attr(@styles.terminal.targeted)
            # @body.attr(@styles.terminal.target_issue) if @state.hasIssue
            return

        if @state.blocked
            @body.attr(@styles.terminal.blocked)
            return

        if @state.available
            @body.attr(@styles.terminal.available)
