module.exports = [
    {path: '/welcome', name: 'welcome', component: require('./view/welcome').default}
    {path: '/dashboard', name: 'dashboard', component: require('./view/dashboard').default}
    {path: '/events/:group/:yard', name: 'events', component: require('./view/events').default}
    {path: '/config/:group/:yard', name: 'config', component: require('./view/config').default}
    {path: '/operators/:group/:yard', name: 'operators', component: require('./view/operators').default}
    {path: '/graphic/:group/:yard', name: 'graphic', component: require('./view/graphic').default}
    {path: '*', redirect: '/welcome'}
    {path: '/', redirect: '/welcome'}
]
