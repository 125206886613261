/*
class of each virtual zone Item
*/

const EventEmitter = require("eventemitter3");

module.exports = class Zone extends EventEmitter {
    /*
    create a new instance of a zone element
    `name` is this label name
    `layers` is an array with layered canvases
    `ops` are this label parameters
    */
    constructor(name, layers, styles, ops) {
        super(...arguments);

        this.name = name;
        this.layers = layers;
        this.styles = styles;
        this.ops = ops;
        this.rails = {};

        this.group = this.layers[3].group();

        const scale = 1.7;

        this.body = this.group
            .rect(this.ops.w, this.ops.h)
            .attr({ fill: "#f33", stroke: "#444", "stroke-width": 1 })
            .center(this.ops.x, this.ops.y);

        this.group
            .text(this.ops.label)
            .font({ size: this.styles.idFontSize, fill: "white", stroke: "#999", "stroke-width": 0.5 })
            .translate(this.ops.x + this.ops.lx, this.ops.y + this.ops.ly);

        const content = this.group.group();
        this.cars = content
            .text("xx")
            .attr({ "text-anchor": "middle", "dominant-baseline": "middle", "font-weight": "bold" })
            .font({ size: this.ops.fs, fill: "black", stroke: "#999", "stroke-width": 0.5 });

        this.axels = content
            .text("xx")
            .attr({ "text-anchor": "middle", "dominant-baseline": "middle", "font-weight": "bold" })
            .font({ size: this.ops.fs / scale, fill: "black", stroke: "#999", "stroke-width": 0.5 })
            .dy(this.ops.fs / scale);

        content.center(this.ops.x, this.ops.y);

        this.body.rotate(this.ops.a);

        this.group.click(() => this.emit("click", "vZone", this.name, this.ops));

        // this.setState({ count: 12, divisor: 4 });
        this.state = { count: "xx" };
    }

    /*
    saves a reference to the rail connected to `port`
    port should be one of `Z1` or 'Z2'
    it will return the size of the zone connected to that port
    */
    setConnection(id, rail, port) {
        switch (port) {
            case "Z1":
                this.rails.A = { rail, id };
                return 1;
            case "Z2":
                this.rails.B = { rail, id };
                return 1;
        }
    }

    /*
    show some highlight on this object.
    it can be `positive` or `negative` to indicate if the command was accepted or not
     */
    highlight() {
        return 0;
    }

    /*
    sets this zone state
    `state` is an object with properties:
    -
    */
    setState(state) {
        this.state = Object.assign({}, this.state, state);

        if (typeof this.state.count == "number") {
            this.cars.text(~~(this.state.count / this.state.divisor));
            if (this.state.count) {
                this.axels.text(this.state.count);
                this.axels.show();
            } else {
                this.axels.text(this.state.count);
                this.axels.hide();
            }
        } else {
            this.cars.text(this.state.count);
            this.axels.hide();
        }

        if (this.state.count === 0) {
            this.body.attr({ fill: this.state.doubt ? "yellow" : "#ccc" });
        } else {
            this.body.attr({ fill: this.state.doubt ? "yellow" : "#f33" });
        }

        this.rails.A.rail.setZone(this.rails.A.id, this.state.count !== 0 && this.state.count != "xx");
        this.rails.B.rail.setZone(this.rails.B.id, this.state.count !== 0 && this.state.count != "xx");

        this.rails.A.rail.setState(this.rails.A.id, { routed: this.state.routed });
        this.rails.B.rail.setState(this.rails.B.id, { routed: this.state.routed });
    }
};
