###
describe a rail element
###

EventEmitter = require 'eventemitter3'

module.exports = class Rail extends EventEmitter

    ###
    create a new instance of rail
    `name` is this rail's name
    `layers` is an array with layered canvases
    `items` is an object with all the graphical elements (terminals and switches)
    `a` is the descriptor for the point 1 of this rail
    `b` is the descriptor for the point 2 of this rail
    `styles` is the styling object
    `ops` are the rail graphical parameters

    the descriptors `a` and `b` are an array with the name of that point and, in case
    it's a switch, it terminal. like: `['T1']` or `['S2', 'E']`
    ###
    constructor: (@name, @layers, items, a, b, @styles, @ops) ->
        super arguments...

        @state = {}

        # console.log @name, a, b, @ops

        # get the two items (terminal, switch point, etc), connected by this rail
        i1 = items[a[0]]
        i2 = items[b[0]]

        # console.log i1, i2

        # get action of start and end zones
        # 0 means we don't care it is a terminal
        # positive is an active thing like a switch and may request signaling
        # negative is an dark thing
        # dark has precedence over internal segments until meet the other end or a negative gap
        z1 = i1.setConnection 1, @, a[1]
        z2 = i2.setConnection 2, @, b[1]

        # get coordinates of each end of this rail
        switch a[1]
            when 'E' then  Ax = i1.entry[0]; Ay = i1.entry[1]
            when 'N' then  Ax = i1.norm[0];  Ay = i1.norm[1]
            when 'R' then  Ax = i1.rev[0];   Ay = i1.rev[1]
            # when 'Z1' then Ax = i1.ops.x;    Ay = i1.ops.y
            # when 'Z2' then Ax = i1.ops.x2;   Ay = i1.ops.y2
            else           Ax = i1.ops.x;    Ay = i1.ops.y   # point a is a terminal or light

        switch b[1]
            when 'E' then  Bx = i2.entry[0]; By = i2.entry[1]
            when 'N' then  Bx = i2.norm[0];  By = i2.norm[1]
            when 'R' then  Bx = i2.rev[0];   By = i2.rev[1]
            # when 'Z1' then Bx = i2.ops.x;    By = i2.ops.y
            # when 'Z2' then Bx = i2.ops.x2;   By = i2.ops.y2
            else           Bx = i2.ops.x;    By = i2.ops.y   # point b is a terminal or light

        points = @ops.points.slice()  # clone array so we can modify it

        # ensure the line between two switches have at least one joint in the middle
        if z1 > 0 and z2 > 0 and !points.length
            alpha = Math.atan2 (By - Ay), (Bx - Ax)
            d2 = (Math.sqrt (Bx-Ax)**2 + (By-Ay)**2) / 2
            points.push [(Math.cos(alpha)*d2), (Math.sin(alpha)*d2), 10]

        points.push [Bx-Ax, By-Ay, 0]  # add endpoint as an offset from the start point

        @dead = z1 == -1 or z2 == -1 or z1 == z2 == 0

        c = if @dead then @styles.railDarkColor else @styles.railColor


        @segments = []
        curX = Ax
        curY = Ay
        for p in points
            x = Ax + p[0]
            y = Ay + p[1]

            dx = dy = 0
            if p[2] > 0
                alpha = 3.1415 + Math.atan2 (y - curY), (x - curX)
                dx = Math.cos(alpha) * p[2]
                dy = Math.sin(alpha) * p[2]

            @segments.push (@layers[1].line(curX, curY, (x + dx), (y + dy))
                .attr({'stroke-width':@styles.railWidth, 'stroke-linecap':'round', stroke:c})
                )
            curX = x
            curY = y

        @Alen = 0
        if z1 > 0
            for s , i in @segments
                s.attr({stroke:'#a22'}) if @ops.dev
                @Alen++
                break unless points[i][2] < 0

        @Blen = 0
        if z2 > 0
            for i in [@segments.length..1] by -1  # '..' is end inclusive
                @segments[i - 1].attr({stroke:'#292'}) if @ops.dev
                @Blen++
                if i >= 2
                    break unless points[i - 2][2] < 0

        # if @segments.length > 3
        #     console.log @segments
        #     console.log points
        #     console.log @Alen, @Blen

        if @ops.label
            @layers[1].text(@ops.label)
            .translate(Ax, Ay)
            .font({size:@styles.idFontSize, fill:'#fff', stroke:'#999'})

        if @ops.dev
            r.click(=> @emit 'click', @name, @ops) for r in @segments

    ###
    ###
    setState: (id, state) ->
        # console.log @name, state
        @state = Object.assign {}, @state, state

        # if @ops.dark
        #     @rail.attr({stroke: @styles.railDarkColor})
        #     return

        # @rail.attr({stroke: @styles.railColor})

        # if @state.mow
        #     @rail.attr({stroke: @styles.switch.mow})

        # if @state.block
        #     @rail.attr({stroke: 'blue'})

        # console.log '----', @iii, id, state

        # return if @ops.dev

        @route1 = state.routed if id == 1
        @route2 = state.routed if id == 2

        start = if @state.z1 then @Alen else 0
        end = if @state.z2 then (@segments.length - @Blen) else @segments.length
        c = if (@route1 or @route2) then @styles.routeColor else @styles.railColor
        # console.log start, end, c
        for i in [start...end] by 1
            @segments[i].attr({stroke: c})


    ###
    ###
    setZone: (id, state) ->
        # if id == 0
            # TODO create a zone attached to this rail

        # return if @ops.dev

        c = if state then @styles.occuColor else @styles.railColor

        if id == 1
            @state.z1 = state
            for i in [0...@Alen]
                @segments[i].attr({stroke: c})

        if id == 2
            @state.z2 = state
            l = @segments.length - 1
            for i in [0...@Blen]
                @segments[l--].attr({stroke: c})
