###
class of each indication light
###

EventEmitter = require 'eventemitter3'


module.exports = class Light extends EventEmitter

    ###
    create a new instance of a indication light
    `name` is the name of that light
    `layers` is an array with layered canvases
    `styles` is the styling object
    `ops` are the light graphical parameters
    ###
    constructor: (@name, @layers, @styles, @ops) ->
        super arguments...

        @rails = {}

        @group = @layers[1].group()

        @l1 = [@ops.x, @ops.y]
        @l2 = [@ops.x, @ops.y]

        @group.path("M-10 0 h 20 m -10 0 v -#{@ops.h} l -5 8 10 0 -5 -8 z")
        .attr({fill:'#999', stroke:'#999', 'stroke-width':3})
        .translate(@ops.x+@ops.ox, @ops.y+@ops.oy)

        @spot = @group.circle(@ops.s).center(@ops.x+@ops.ox+@ops.cx, @ops.y+@ops.oy+@ops.cy).attr({fill:'#44f'})
        @alert = @group.circle(30).center(@ops.x, @ops.y)
        @group.circle(@ops.s).center(@ops.x+@ops.ox+@ops.cx, @ops.y+@ops.oy+@ops.cy).attr({fill:'transparent', stroke:'#999', 'stroke-width':2})

        @group.text(@name)
        .translate(@ops.x+@ops.lx, @ops.y+@ops.ly)
        .font({size:@styles.idFontSize, fill:'#fff', stroke:'#999'})
        .rotate(@ops.la)

        @click = @layers[2]
            .circle(@styles.clickSize)
            .center(@ops.x+@ops.ox+@ops.cx, @ops.y+@ops.oy+@ops.cy)
            .attr({fill:'#2f6', 'fill-opacity':.05})
            .click => @emit 'click', 'light', @name, @ops

        @group
        .scale(@ops.zoom || 1)
        .rotate(@ops.a || 0)
        .click => @emit 'click', 'light', @name, @ops


    ###
    show some highlight on this object.
    it can be `positive` or `negative` to indicate if the command was accepted or not
    ###
    highlight: (type) ->
        console.log 'piscando.....', type
        # @click.attr {'fill-opacity':1}
        # setTimeout =>
        #     @click.attr {'fill-opacity':.05}
        # , 150


    ###
    saves a reference to the rail connected to `port`
    ###
    setConnection: (id, rail, port) -> return 0


    ###
    sets this light state
    `state` is an object with properties:
    -
    ###
    setState: (state) ->
        @state = Object.assign {}, @state, state

        @spot.attr({opacity:.02})
        @alert.stop().attr({opacity:0})
        # @rails.L1.rail.setState @rails.L1.id, {block: no}
        # @rails.L2.rail.setState @rails.L2.id, {block: no}

        if @state.offline
            @alert.attr({opacity:1, fill:@styles.switch.offline})
            return

        if @state.active
            @spot.attr({opacity:1})

        # console.log @name, @state

        # if @state.block in ['A', 'L']
        # # if @state.block in ['A', 'R']
        # # if @state.block in ['A']
        #     @rails.L1.rail.setState @rails.L1.id, {block: yes}

        # if @state.block in ['A', 'R']
        # # if @state.block in ['A', 'L']
        # # if @state.block in ['A']
        #     @rails.L2.rail.setState @rails.L2.id, {block: yes}

        if @state.fault
            @alert.attr({fill:'orange'}).animate(600, '-').attr({opacity:1}).loop(null, true)
