import Vue from 'vue'

# console.log process.versions

store  = require 'root/store'

Vue.use require 'vue-shortkey'

Vue.cfg = Vue::$cfg = require './config'





import 'quasar/dist/quasar.min.css'
import Quasar from 'quasar/dist/quasar.umd.min.js'
# import Quasar, * as All from 'quasar'

#! failed to load icons from here --- we set it on our entry.vue mounted function

Vue.use Quasar, {
    # components: All
    # iconSet
}




import VueI18n from 'vue-i18n'
Vue.use VueI18n
i18n = new VueI18n {
    locale: store.state.cfg?.language || 'en'
    # locale: 'es'
    fallbackLocale: 'en'
    messages: require 'root/i18n/i18n.json'
}


EventEmitter = require 'eventemitter3'
Vue.hub = Vue::$hub = new EventEmitter()

# require('moment').locale 'pt'

Vue.hub.on 'setLang', (lang) ->
    console.log 'Setting language to:', lang
    # q.i18n.set langs[lang]
    i18n.locale = lang
    require('moment').locale lang


import Router from 'vue-router'
Vue.use Router
# isLoged = (to, from, next) -> if store.get('authenticated') then next() else next({name: 'login'})
router = new Router
    scrollBehavior: (to, from, saved) -> if saved then saved else { x: 0, y: 0 }
    routes: require './routes'


Vue::$http = require('axios').create({baseURL: Vue.cfg.apiURL, withCredentials:yes})
Vue::$http.interceptors.response.use ((response) -> return response), (error) ->
    console.log 'DEU RUIM', error
    router.replace {name: 'welcome'} if error.response.status == 401
    return Promise.reject error

import App from './app'

app = new Vue {
    el: '#app'
    router
    i18n
    store
    render: (h) => h App
}


# isMobile = navigator.userAgent.toLowerCase().indexOf('mobile') >= 0

# if isMobile
#     console.log 'instalando SW'
#     sw = require('magicworker/lib/runtime-service-worker.js') {
#         swFilename: require 'file-loader?name=sw.js!./sw.coffee'
#     }
# else
#     console.log 'ignorando SW'
