textsEN = {
}

textsES = {
    elABORTROUTE: 'Cancelada creación de ruta'
    elADDBLOCK: 'Usuario {0} añadio bloqueo al cambio {1}. Motivo: {2}'
    elADVANCE: 'Avanzo manual de lista de corte'
    elAUTOADVANCE: 'Avanzo automático de lista de corte'
    elAUTOLOGOUT: 'Usuario expiró'
    elAUTOMODE: '{0} cambió modo de operación para {1}'
    elAUTOOPMODE: 'Cambio automático para el modo {0}'
    elBLOCKS: 'Cambio {0} tiene {1} bloqueos activos'
    elBOOT: 'Pathfinder iniciado'
    elBROADCASTED: 'Display actualizado'
    elBROKEROUTE: 'Ruta de {0} para {1} se Dañó'
    elBUILDMANUALROUTE: 'Requerida ruta MANUAL de {0} para {1}'
    elBUILTAUTOROUTE: 'Lista ruta automática de {0} para {1}'
    elCMDSWITCH: 'Enviado comando para cambio {0} para posición {1}'
    elCOMMERROR: 'Error de comunicación por el canal {0}'
    elCREATEDUSER: 'Nuevo usuario {1} fue creado por {0}'
    elDONEMANUALROUTE: 'Lista ruta MANUAL de {0} para {1}'
    elEDITEDMAP: 'Usuario {0} hizo una edición en el mapa'
    elLOGIN: 'Usuario {0} entro al sistema'
    elLOGOUT: 'Usuario salió del sistema'
    elMANUALMOVESWITCH: 'Cambio {0} se movió de forma local para la posición {1}'
    elOPMODE: 'Cambio manual para el modo {0}'
    elREMOTEREST: 'Remote hard reset of communication'
    elREMOVEDUSER: 'Usuario {1} fue borrado por {0}'
    elRMBLOCK: 'Usuario {0} removió bloqueo al cambio {1}. Motivo: {2}'
    elSETCUTLIST: 'Una lista con {1} grupos fue creada por {0}'
    elSWITCHFAULT: 'Cambio {0} indicación de falla {1}'
    elSWITCHMOVED: 'Cambio {0} movió para la posición {1}'
    elSWITCHMOW: 'Cambio {0} MOW esta {1}'
    elSWITCHOCCU: 'Cambio {0} indicación de ocupación es {1}'
    elSWITCHOFFLINE: 'Cambio {0} indicación de comunicación es {1}'
    elSWITCHOOC: 'Cambio {0} indicación de correspondencia es {1}'
    elSWITCHROUTED: 'Cambio {0} indicación de ruta es {1}'
}


processSwitchStatus = ([date, event, sw, state]) ->
    # console.log date, sw, state, event
    [stat, types...] = state.split ','
    infos = types.map (type) ->
        switch type
            # when 'Boot'
            # when 'Refresh'
            when 'Pos'
                return 'Normal' if 'N' in stat
                return 'Abierto' if 'O' in stat
                return 'Reversa' if 'R' in stat
            when 'Occu'
                return if 'U' in stat then 'Ocupado' else 'Libre'
            when 'Fault'
                return if 'F' in stat then 'com-Falla' else 'sin-Falla'
            when 'MOW'
                return if 'M' in stat then 'Mantenimiento' else 'fuera-de-Mantenimiento'
            when 'OOC'
                return if 'C' in stat then 'sin-Correspondencia' else 'Correspondencia'
            when 'Offline'
                return if 'X' in stat then 'OFFline' else 'ONline'
            when 'Route'
                return if 'T' in stat then 'em-Ruta' else 'sin_Ruta'
            when 'Block'
                return if 'B' in stat then 'Bloqueada' else 'sin-Bloqueo'
    .filter (item) -> item?.length

    return "Cambio #{sw} esta #{infos.join(' and ')}" if infos.length

processLightStatus = ([date, event, name, state]) ->
    # console.log date, name, state, event
    [stat, types...] = state.split ','
    infos = types.map (type) ->
        switch type
            when 'Active'
                return if 'A' in stat then 'Prendida' else 'Apagada'
            when 'Fault'
                return if 'F' in stat then 'Falla' else 'Normal'
            when 'Offline'
                return if 'X' in stat then 'OFFline' else 'ONline'
    .filter (item) -> item?.length

    return "Blue Flag #{name} esta #{infos.join(' and ')}" if infos.length




module.exports =

    format: (rawEvents, ops) ->
        formated = rawEvents.map (line) =>
            tmp = line.split '!'

            # d = (new Date tmp[0]).toJSON()?.slice(11,23)
            # d = moment(tmp[0]).format('D/MMM HH:mm:ss.S')
            dtmp = tmp[0].split /[T:.-]/
            # console.log tmp[0], dtmp
            d = "#{dtmp[2]}/#{dtmp[1]} #{dtmp[3]}:#{dtmp[4]}:#{dtmp[5]}.#{dtmp[6]?[0]}"
            style = ''
            text = ''
            templ = textsES[tmp[1]]

            if ops.status and tmp[1] == 'elSWSTATUS'
                text = processSwitchStatus tmp
                style = 'el-swstatus'

            if not text and ops.lights and tmp[1] == 'evLIGHTSTATUS'
                text = processLightStatus tmp
                style = 'el-light'

            if not text and ops.curList and tmp[1] == 'elSETCUTLIST'
                text = templ
                .replace('{0}', tmp[2])
                .replace('{1}', (JSON.parse(tmp[3])).list.length)
                style = 'el-cutlist'

            if not text and ops.commands and tmp[1] == 'elCMDSWITCH'
                style = 'el-switchcmd'
                text = templ
                .replace('{0}', tmp[2])
                .replace '{1}', switch tmp[3]
                    when 'N' then 'Normal'
                    when 'R' then 'Reverse'
                    when 'O' then 'Open'

            if not text and ops.routes
                text = switch tmp[1]
            #         when 'elMANUALMOVESWITCH'  <<== never used
                    when 'elBUILDMANUALROUTE', 'elDONEMANUALROUTE'
                        templ.replace('{0}', tmp[2]).replace('{1}', tmp[3])
                    when 'elABORTROUTE'
                        templ
                style = 'el-route' if text

            if not text and ops.autoRoutes
                text = switch tmp[1]
                    # when 'elBROKEROUTE'    # <<== never used
                    when 'elBROADCASTED'
                        templ
                    when 'elBUILTAUTOROUTE'
                        templ.replace('{0}', tmp[2]).replace('{1}', tmp[3])
                style = 'el-routeauto' if text

            if not text and ops.login
                text = switch tmp[1]
                    # when 'elAUTOLOGOUT'   # <<== never used
                    when 'elLOGIN', 'elLOGOUT', 'elCREATEDUSER', 'elREMOVEDUSER'
                        templ.replace('{0}', tmp[2]).replace('{1}', tmp[3])
                style = 'el-login' if text

            if not text and ops.modes
                text = switch tmp[1]
                    when 'elOPMODE', 'elAUTOOPMODE'
                        templ.replace('{0}', tmp[2].toUpperCase())
                    when 'elADVANCE', 'elAUTOADVANCE'
                        templ
                style = 'el-modes' if text

            if not text and ops.blocks
                text = switch tmp[1]
                    when 'elADDBLOCK'
                        block = JSON.parse tmp[3]
                        templ
                        .replace('{0}', block.user)
                        .replace('{1}', tmp[2])
                        .replace('{2}', block.reason)
                    when 'elRMBLOCK'
                        block = JSON.parse tmp[3]
                        templ
                        .replace('{0}', block.user)
                        .replace('{1}', tmp[2])
                        .replace('{2}', block.reason)
                    when 'elBLOCKS'
                        block = JSON.parse tmp[3]
                        templ
                        .replace('{0}', tmp[2])
                        .replace('{1}', (block || []).length)
                style = 'el-block' if text

            unless text
                # style = 'el-modes'
                text = switch tmp[1]
                    when 'elBOOT'
                        templ.replace('{0}', tmp[2]).replace('{1}', tmp[3])
                    when 'elREMOTEREST'
                        templ

            if text
                return if ops.csv
                    "#{style[3...]};#{d};#{text}\n"
                else
                    "<div class=\"log-item #{style}\"><b>#{d}</b> - #{text}</div>"

        return formated.join('')
