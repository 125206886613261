











































































































































































































































































































































































Promise = require 'bluebird'
mousetrap = require 'mousetrap'


module.exports =
    name: 'graphic'

    data: ->
        version: 1
        yardCfg: null
        views: []
        view: 'full'
        gui: null
        devMode: true
        # editor: null
        # draw: null
        globalStep: 50
        globalItem: 'Sw+Ter'
        globalMode: 1
        selectedItems: {}
        showDown: yes
        controlType: 'general'
        selected: null
        selectedType: null
        saved: no
        editObj: {}
        copyObj: null
        showGuides: no
        guide:
            x: 200
            y: 200
            c30: -500
            c45: -500
            lines:
                h: null
                v: null
                c30: null
                c45: null
        yardStyles:
            # background: '#333'
            normColor: 'green'
            revColor: 'orange'
            terminal:
                normal: {fill:'#bb8', stroke:'#fff'}

    components:
        yardMap: require('../comp/yard-map').default
        niceInput: require('../comp/nice-input').default

    created: ->
        group = @$route.params.group
        yard = @$route.params.yard

        @$store.commit 'SET_YARD', {yard: group+'/'+yard, kind: @$i18n.t('graphic.subtitle')}

        @$http.get "yards/configuration/#{group}/#{yard}"
        .then (res) =>
            # console.log res.data
            @yardCfg = res.data
            @gui = @yardCfg.gui
            @views = @yardCfg.gui.views
        .catch (err) =>
            console.error err

    mounted: ->
        @installSelector()

        mousetrap.bind 'c', =>
            if @editObj
                @copyObj = JSON.parse JSON.stringify @editObj

        mousetrap.bind 'x', =>
            @editObj.x = @copyObj.x if @editObj and @copyObj?.x
            @paint()

        mousetrap.bind 'y', =>
            @editObj.y = @copyObj.y if @editObj and @copyObj?.y
            @paint()

        mousetrap.bind 'w', =>
            @editObj.w = @copyObj.w if @editObj?.w? and @copyObj?.w?
            @paint()

        mousetrap.bind 'h', =>
            @editObj.h = @copyObj.h if @editObj?.h? and @copyObj?.h?
            @paint()

        mousetrap.bind 'a', =>
            @editObj.a = @copyObj.a if @editObj?.a? and @copyObj?.a?
            @editObj.ra = @copyObj.ra if @editObj?.ra? and @copyObj?.ra?
            @paint()

        mousetrap.bind 'l', =>
            @editObj.lx = @copyObj.lx if @editObj?.lx? and @copyObj?.lx?
            @editObj.ly = @copyObj.ly if @editObj?.ly? and @copyObj?.ly?
            @editObj.la = @copyObj.la if @editObj?.la? and @copyObj?.la?
            @editObj.fs = @copyObj.fs if @editObj?.fs? and @copyObj?.fs?
            @paint()

        mousetrap.bind 's', =>
            @editObj.es = @copyObj.es if @editObj?.es? and @copyObj?.es?
            @editObj.ns = @copyObj.ns if @editObj?.ns? and @copyObj?.ns?
            @editObj.rs = @copyObj.rs if @editObj?.rs? and @copyObj?.rs?
            @editObj.w = @copyObj.w if @editObj?.w? and @copyObj?.w?
            @editObj.h = @copyObj.h if @editObj?.h? and @copyObj?.h?
            @paint()

    beforeRouteLeave: (to, from, next) ->
        unless @saved
            @$q.dialog {
                title: 'Not saved!'
                ok: {label: 'Leave, I don\'t care!', color: 'negative'}
                cancel: 'Oops, Stay here!'
            }
            .onCancel -> next false
            .onOk ->
                mousetrap.reset()
                next()

        else
            next()
            mousetrap.reset()

    methods:
        save: ->
            svg = document.getElementById("svgYardArea")
            www = svg.getAttribute 'width'
            hhh = svg.getAttribute 'height'
            svg.setAttribute 'width', "#{@gui.size[0]}px"    # this is mandatory to work on firefox
            svg.setAttribute 'height', "#{@gui.size[1]}px"
            canvas = @$refs.tmpCanvas
            ctx = canvas.getContext '2d'
            curView = @view
            Promise.mapSeries @views, (view) =>
                @$refs.yard.setView view
                new Promise (resolve) =>
                    setTimeout =>
                        loader = new Image;
                        loader.width = canvas.width = @yardCfg.gui.size[0] / 5
                        loader.height = canvas.height = (@yardCfg.gui.size[1] - @yardCfg.gui.styles.topBarHeight) / 5
                        loader.onload = ->
                            ctx.drawImage loader, 0, 0, loader.width, loader.height
                            resolve canvas.toDataURL()
                            console.log 'rendered', view
                        svgAsXML = (new XMLSerializer).serializeToString svg
                        loader.src = 'data:image/svg+xml,' + encodeURIComponent svgAsXML
                    , 150
            .then (thumbs) =>
                @gui.thumbs = {}
                @gui.thumbs[view] = thumbs[idx] for view, idx in @views
                gui = JSON.parse JSON.stringify @gui
                for view in gui.views
                    for sw, o of gui[view].switches
                        delete o.dev
                        delete o.xover
                        delete o.remote
                        delete o.manual
                        delete o.derail
                    for r, o of gui[view].rails
                        delete o.dev
                obj = {gui, yid:@$route.params.yid}

                #? we could add query 'keepThumbs' in case we don't want to
                #? have them stripped out by the api

                @$http.post "yards/graphic/#{@$route.params.group}/#{@$route.params.yard}", obj, {}
            .then =>
                @$q.notify {type:'positive', position:'top-right', message:@$i18n.t('graphic.done')}
                @saved = yes
                @$refs.yard.setView curView
                svg.setAttribute 'width', www
                svg.setAttribute 'height', hhh
            .catch (err) =>
                console.error err
                @$q.notify {type:'negative', position:'top-right', message:@$i18n.t('graphic.failed')}

        paint: (force) ->
            clearTimeout debTimer if debTimer
            debTimer = setTimeout =>
                debTimer = null
                @version++ if force
                @$refs.yard.setView @view
                @$refs.yard.paint()
                # for sw of @yardCfg.remotes
                #     @$refs.yard.update sw, {dev:yes, routed:no, occu:yes}
                @addGuides() if @showGuides
            , 350

        updateAndPaint: ->
            @saved = no
            if @selected of @gui[@view].switches
                Object.assign @gui[@view].switches[@selected], @editObj
                @paint()

            if @selected of @gui[@view].terminals
                Object.assign @gui[@view].terminals[@selected], @editObj
                @paint()

            if @selected of @gui[@view].rails
                Object.assign @gui[@view].rails[@selected], @editObj
                @paint()

            if @selectedType == 'asset'
                tmp = @selected.split '-'
                Object.assign @gui[@view].assets[tmp[1]], @editObj
                @paint()

            if @selectedType == 'label'
                tmp = @selected.split '-'
                Object.assign @gui[@view].labels[tmp[1]], @editObj
                @paint()

            if @selectedType == 'editor'
                for e in @gui[@view].editors
                    if e.id == @editObj.id
                        Object.assign e, @editObj
                @paint()

            if @selected of @gui[@view].lights
                Object.assign @gui[@view].lights[@selected], @editObj
                @paint()

            if @selected of @gui[@view].zones
                Object.assign @gui[@view].zones[@selected], @editObj
                @paint()

            if @selectedType == 'control'
                Object.assign @gui[@view].controls[@selected], @editObj
                @paint()

        # onDone: ->
        #     console.log 'done!'
        #     @paint()
            # for sw, obj of @yardCfg.switches
            #     @$refs.yard.update sw, {
            #         pos:'R'
            #         # occu: yes
            #         # fault: yes
            #         # ooc: yes
            #         # mow: yes
            #     }
                # @commBuf[obj.id] = {sw, data: {0: 64, 1: 0, 0xe0: 0}}
                # @$refs.yard.update sw, @states[sw]
            # @$refs.yard.update '020TS', {pos:'N', occu:yes, routed:true, ooc:no}
            # @$refs.yard.update '031SSL', {pos:'R', occu:yes, routed:true}


        onSelect: (kind, name, obj) ->
            console.log kind, name, obj
            @selectedType = kind
            @selected = name
            @editObj = obj
            @controlType = 'general'


        addAsset: (event) ->
            file = event.target.files[0]

            unless file.type == 'image/svg+xml'
                @$q.notify {type:'negative', position:'top-right', message:@$i18n.t('graphic.notSVG')}
                return

            reader = new FileReader()
            reader.onload = (e) =>
                svg = e.target.result

                @$q.loading.show {}
                @$http.post "yards/prepareAsset", {svg}, {}
                .then (res) =>
                    @gui[@view].assets.push {
                        x: ~~(Math.random()*800)
                        y: ~~(Math.random()*800)
                        zoom: 1
                        a: 0
                        order: 0
                        content: res.data
                    }
                    @$q.loading.hide()
                    @paint()
                .catch =>
                    @$q.loading.hide()
                    @$q.notify {type:'negative', position:'top-right', message:'Oops!'}

            reader.readAsText file

        removeAsset: ->
            @gui[@view].assets = @gui[@view].assets.filter (item) =>
                not (item.x == @editObj.x and item.y == @editObj.y)

            @paint()

        addLabel: ->
            @gui[@view].labels.push {
                x: ~~(Math.random()*700)
                y: ~~(Math.random()*700)
                text: 'Label'
                a: 0
                fs: 22
                order: 0
                bg: 'orange'
                fg: 'black'
                p: 10          # padding
            }
            @paint()

        removeLabel: ->
            @gui[@view].labels = @gui[@view].labels.filter (item) =>
                not (item.x == @editObj.x and item.y == @editObj.y)

            @paint()

        addEditor: ->
            @gui[@view].editors.push {
                x: ~~(Math.random()*1000)
                y: ~~(Math.random()*1000)
                w: 140
                h: 60
                text: ''
                type: 'number'
                id: 'ed' + (~~(Math.random()*999) + 1)
                a: 0
                fs: 40
                bg: '#66ea'
                fg: 'white'
            }
            @paint()

        removeEditor: ->
            @gui[@view].editors = @gui[@view].editors.filter (item) =>
                not (item.id == @editObj.id)

            @paint()

        changeView: ->
            @$refs.yard.setView @view

        addRailPoint: ->
            @gui[@view].rails[@selected].points.push [0, 0, 0]
            @paint()

        removeRailPoint: (idx) ->
            @gui[@view].rails[@selected].points.splice idx, 1
            @paint()

        globalApplyStep: (type) ->
            switch @globalItem
                when 'Sw+Ter'
                    all = Object.assign {}, @gui[@view].switches, @gui[@view].terminals, @gui[@view].controls
                    p = {x:'x', y:'y'}
                when 'Sw'
                    all = @gui[@view].switches
                    p = {x:'lx', y:'ly'}
                when 'Ter'
                    all = @gui[@view].terminals
                    p = {x:'lx', y:'ly'}
                when 'G'
                    all = @selectedItems
                    p = {x:'x', y:'y'}
                else
                    return

            switch type
                when '+x'  # move to right
                    for k, v of all
                        v[p.x] += @globalStep

                when '-x'  # move to left
                    for k, v of all
                        v[p.x] -= @globalStep

                when '+y'  # move down
                    for k, v of all
                        v[p.y] += @globalStep

                when '-y'  # move up
                    for k, v of all
                        v[p.y] -= @globalStep

                when '+w'
                    if @globalItem == 'Sw+Ter' or @globalMode == 2 # expand horizontally
                        c = @gui.size[0] / 2
                        r = (@globalStep / 2) / c
                        for k, v of all
                            if v.x >= c
                                v.x += (v.x - c) * r
                            else
                                v.x -= (c - v.x) * r
                    else  # if only terminal are selected then make then wider
                        for k, v of all
                            v.w += @globalStep

                when '-w'
                    if @globalItem == 'Sw+Ter' or @globalMode == 2  # compress horizontally
                        c = @gui.size[0] / 2
                        r = (@globalStep / 2) / c
                        for k, v of all
                            if v.x >= c
                                v.x -= (v.x - c) * r
                            else
                                v.x += (c - v.x) * r
                    else  # if only terminal are selected then make then narrower
                        for k, v of all
                            v.w -= @globalStep

                when '+h'
                    if @globalItem == 'Sw+Ter' or @globalMode == 2  # expand vertically
                        c = @gui.size[1] / 2
                        r = (@globalStep / 2) / c
                        for k, v of all
                            if v.y >= c
                                v.y += (v.y - c) * r
                            else
                                v.y -= (c - v.y) * r
                    else  # if only terminal are selected then make then taller
                        for k, v of all
                            v.h += @globalStep

                when '-h'
                    if @globalItem == 'Sw+Ter' or @globalMode == 2  # compress vertically
                        c = @gui.size[1] / 2
                        r = (@globalStep / 2) / c
                        for k, v of all
                            if v.y >= c
                                v.y -= (v.y - c) * r
                            else
                                v.y += (c - v.y) * r
                    else  # if only terminal are selected then make then shorter
                        for k, v of all
                            v.h -= @globalStep

                when 'round'  # round all values
                    for k, v of all
                        v.x = Math.round v.x
                        v.y = Math.round v.y
                        v.lx = Math.round v.lx
                        v.ly = Math.round v.ly

                when 'ah'  # align all selected horizontally
                    x = null
                    for k, v of all
                        if x
                            v.x = x
                        else
                            x = v.x

                when 'av'  # align all selected vertically
                    y = null
                    for k, v of all
                        if y
                            v.y = y
                        else
                            y = v.y

                when 'sh'  # spread selected evenlly horizontally
                    l = []
                    l.push {k, x:v.x} for k, v of all
                    l.sort (a, b) -> a.x - b.x
                    d = (l.slice(-1)[0].x - l[0].x) / (l.length - 1)
                    p = l[0].x
                    for e in l
                        all[e.k].x = p
                        p += d

                when 'sv'  # spread selected evenlly vertically
                    l = []
                    l.push {k, y:v.y} for k, v of all
                    l.sort (a, b) -> a.y - b.y
                    d = (l.slice(-1)[0].y - l[0].y) / (l.length - 1)
                    p = l[0].y
                    for e in l
                        all[e.k].y = p
                        p += d

            @paint()

        installSelector: ->

            aim = null
            points = null
            pol = null

            mousetrap.bind 'g', =>
                console.log 'starting selection'
                {draw, rect, SVG} = @$refs.yard.getSVG()

                aim = draw.path 'M-180-180 180 180M-180 180 180-180M-480 0h960M0-480v960'
                .attr {'stroke-width': 3, stroke: '#999'}

                points = []

                # rect.dblclick     -> console.log 'dblclick'
                # rect.mousedown    -> console.log 'mousedown'
                # rect.mouseup      -> console.log 'mouseup'
                # rect.mouseover    -> console.log 'mouseover'

                # rect.mouseout ->
                #     console.log 'mouseout'
                #     mousetrap.trigger 'esc'

                aim.click (e) ->
                    point = rect.point e.pageX, e.pageY
                    points.push [point.x, point.y]
                    if points.length == 2
                        pol = draw.polygon(points).attr({fill:"#876ee166"})
                    else if points.length > 2
                        pol.plot points

                rect.mousemove (e) ->
                    point = rect.point e.pageX, e.pageY
                    aim.cx point.x
                    aim.cy point.y

            mousetrap.bind 'esc', =>
                {rect} = @$refs.yard.getSVG()
                aim.click null
                aim.remove()
                aim = null
                points = null
                pol.remove() if pol
                pol = null
                rect.mouseout null
                rect.mousemove null

            mousetrap.bind 'enter', =>
                return unless points?.length > 2

                points.push points[0]

                obj = {}

                # filterInside obj, points, @gui[@view].assets   !fail since is an array
                filterInside obj, points, @gui[@view].controls
                # filterInside obj, points, @gui[@view].labels   !fail since is an array
                # filterInside obj, points, @gui[@view].editors   !fail since is an array
                filterInside obj, points, @gui[@view].lights
                filterInside obj, points, @gui[@view].rails
                filterInside obj, points, @gui[@view].switches
                filterInside obj, points, @gui[@view].terminals
                filterInside obj, points, @gui[@view].zones

                console.log obj
                @globalItem = 'G'
                @selectedItems = obj
                @controlType = 'global'

                mousetrap.trigger 'esc'

            ###
            https://www.baeldung.com/cs/geofencing-point-inside-polygon
            ###
            filterInside = (obj, polygon, items) ->
                for k, v of items
                    inside = no
                    for p1, i in polygon[...-1]
                        p2 = polygon[i + 1]

                        continue if p1[1] > v.y and p2[1] > v.y

                        continue if p1[1] < v.y and p2[1] < v.y

                        sx = p1[0] + ( (p2[0] - p1[0]) * ((v.y - p1[1]) / (p2[1] - p1[1])) )

                        if v.x > sx
                            inside = if inside then no else yes

                    if inside
                        obj[k] = v

        addGuides: ->
            {draw, rect, SVG} = @$refs.yard.getSVG()

            @guide.lines.v = draw.line(0, 0, 0, 10e3).attr({'stroke-width':2, stroke:'#88b'})
            @guide.lines.h = draw.line(0, 0, 10e3, 0).attr({'stroke-width':2, stroke:'#88b'})
            @guide.lines.c30 = draw.group()
            sin = Math.tan(30 * (Math.PI / 180))
            @guide.lines.c30.line(0, 0, -sin*10e3, 10e3).attr({'stroke-width':2, stroke:'#88b'})
            @guide.lines.c30.line(0, 0, +sin*10e3, 10e3).attr({'stroke-width':2, stroke:'#88b'})
            sin = Math.tan(60 * (Math.PI / 180))
            @guide.lines.c30.line(0, 0, -sin*10e3, 10e3).attr({'stroke-width':2, stroke:'#88b'})
            @guide.lines.c30.line(0, 0, +sin*10e3, 10e3).attr({'stroke-width':2, stroke:'#88b'})
            @guide.lines.c45 = draw.group()
            sin = Math.tan(45 * (Math.PI / 180))
            console.log sin
            @guide.lines.c45.line(0, 0, -sin*10e3, 10e3).attr({'stroke-width':2, stroke:'#88b'})
            @guide.lines.c45.line(0, 0, +sin*10e3, 10e3).attr({'stroke-width':2, stroke:'#88b'})

            @showGuides = yes

            @moveGuides()

            console.log 'guias adicionadas!'

        moveGuides: ->
            @guide.lines.v.x(@guide.x)
            @guide.lines.h.y(@guide.y)
            @guide.lines.c30.cx(@guide.c30)
            @guide.lines.c45.cx(@guide.c45)

        exportSVG: ->
            console.log 'exporting...'
            @devMode = false
            oldStyle = @yardStyles
            # @yardStyles = {}
            @version++
            setTimeout =>
                svg = document.getElementById("svgYardArea")
                www = svg.getAttribute 'width'
                hhh = svg.getAttribute 'height'
                svg.setAttribute 'width', "#{@gui.size[0]}px"    # this is mandatory to work on firefox
                svg.setAttribute 'height', "#{@gui.size[1]}px"
                svgAsXML = (new XMLSerializer).serializeToString svg

                a = document.createElement('a')
                blob = new Blob([svgAsXML], {'type': 'image/svg+xml'})
                a.href = window.URL.createObjectURL(blob)
                a.download = 'mapa.svg'
                a.click()
                svg.setAttribute 'width', www
                svg.setAttribute 'height', hhh
                @devMode = true
                @yardStyles = oldStyle
                @version++
            , 1000
