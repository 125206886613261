/*
class of each ControlClassList Item
*/

const EventEmitter = require("eventemitter3");
const { times } = require("lodash");

module.exports = class ControlClassList extends EventEmitter {
    /*
    create a new instance of a control element
    `name` is this label name
    `layers` is an array with layered canvases
    `ops` are this label parameters
    */
    constructor(name, layers, styles, ops) {
        super(...arguments);

        this.name = name;
        this.layers = layers;
        this.styles = styles;
        this.ops = ops;

        const modeBtn = this.layers[3].group();

        this.caption = modeBtn
            .text("a long text")
            .css({ "text-anchor": "middle" })
            .center(this.ops.x, this.ops.y + 12)
            .font({ size: this.ops.fs * 1.5 })
            .fill("white");

        this.btnBody = modeBtn
            .rect(380, 80)
            .radius(8)
            .center(this.ops.x, this.ops.y)
            .attr({ "stroke-width": 1.2, fill: "#88f", stroke: "white" })
            .back();

        modeBtn.click(() => this.emit("click", this.name, this.ops));

        this.listWdg = this.layers[3].group();

        this.listWdg // background
            .rect(380, 440)
            .radius(8)
            .cx(this.ops.x)
            .y(this.ops.y + 55)
            .attr({ fill: "#ccc" });

        this.readyBar = this.listWdg // highlight
            .rect(380, 60)
            // .radius(8)
            .cx(this.ops.x)
            .y(this.ops.y + 70)
            .attr({ fill: "orange" });

        this.text = this.listWdg
            .text("4 --> 12\n4 --> 12\n4 --> 8\n1 --> 15\n4 --> 12\n4 --> 12\n+12")
            .font({ size: this.ops.fs * 1.5 })
            .css({ "text-anchor": "middle" })
            .cx(this.ops.x)
            .y(this.ops.y + 75);

        this.listWdg.click(() => this.emit("click", this.name, "editCL"));

        this.advanceBtn = this.layers[3].group();

        this.advanceBtn
            .rect(380, 90)
            .radius(8)
            .attr({ "stroke-width": 1.2 })
            .attr({ fill: "#e15800", stroke: "white" })
            .cx(this.ops.x)
            .y(this.ops.y + 525);

        this.advanceBtn
            .text("Advance")
            .font({ size: this.ops.fs * 1.5 })
            .css({ "text-anchor": "middle" })
            .cx(this.ops.x)
            .y(this.ops.y + 540)
            .fill("white");

        this.advanceBtn.click(() => {
            if (this.state.mode == "remote") this.emit("click", this.name, "advance");
        });

        this.state = {
            mode: "manual",
            listSize: 0,
            list: [],
            ready: false
        };

        this.setState({});
    }

    /*
    show some highlight on this object.
    it can be `positive` or `negative` to indicate if the command was accepted or not
    */
    highlight(type) {
        if (type == "negative") this.btnBody.attr(this.styles.control.negative);
        if (type == "positive") this.btnBody.attr(this.styles.control.positive);
        setTimeout(() => {
            this.setState();
        }, 250);
    }

    /*
    sets this control state
    `state` is a object with properties:
    - TODO....
    */
    setState(state) {
        this.state = Object.assign({}, this.state, state);

        if (this.ops.dev) {
            this.listWdg.show();
            this.advanceBtn.show();
            return;
        }

        if (this.state.listSize) {
            this.listWdg.show();

            this.readyBar.attr({ fill: this.state.ready ? "#4e9d38" : "#ffb938" });

            // console.log(this.state);
            let t = "";
            for (const c of this.state.list) {
                t += `${c.n} --> ${c.line}\n`;
            }
            if (this.state.listSize > this.state.list.length) t += `+${this.state.listSize - this.state.list.length}`;
            this.text.text(t);
        } else this.listWdg.hide();

        switch (this.state.mode) {
            case "manual":
                this.caption
                    .text(`MANUAL`)
                    .center(this.ops.x, this.ops.y)
                    .fill("white");
                this.btnBody.attr({ fill: "#48f", stroke: "white" });
                this.advanceBtn.hide();
                break;

            case "auto":
                this.caption.text(`AUTO`).fill("white");
                this.btnBody.attr({ fill: "#f64", stroke: "white" });
                this.advanceBtn.hide();
                break;

            case "remote":
                this.caption.text(`REMOTE`).fill("black");
                this.btnBody.attr({ fill: "#fc4", stroke: "white" });
                this.advanceBtn.show();
                break;
        }
    }
};
