###
class of each ControlClassBtn Item
###

EventEmitter = require 'eventemitter3'


module.exports = class ControlClassBtn extends EventEmitter

    ###
    create a new instance of a control element
    `name` is this label name
    `layers` is an array with layered canvases
    `ops` are this label parameters
    ###
    constructor: (@name, @layers, @styles, @ops) ->
        super arguments...


        g = @layers[3].group()

        @text = g.text('blah').font({size:@ops.fs}).css({'text-anchor':'middle'})

        @body = g.rect(10, 10)  # not important....
        .radius(8)
        .attr({'stroke-width':1.2})
        .back()

        @state = {
            state: 'manual'
            listSize: ''
        }


        @advanceBtn = @layers[3].group()

        dy = 100

        text = @advanceBtn.text('Advance')
        .font({size:@ops.fs * 0.75})
        .css({'text-anchor':'middle'})
        .cx(@ops.x).cy(@ops.y + dy)
        .fill('white')

        box = text.bbox()

        @advanceBtn.rect(10, 10)  # not important....
        .radius(8)
        .attr({'stroke-width':1.2})
        .attr({fill:'#e15800', stroke:'white'})
        .back()
        .attr({width:box.w + @ops.p*2 ,height:box.h + @ops.p})
        .center(box.cx, box.cy)

        @advanceBtn.click =>
            if @state.state == 'remote'
                @emit 'click', @name, 'advance'



        @setState {}

        g.click => @emit 'click', @name, @ops

    ###
    show some highlight on this object.
    it can be `positive` or `negative` to indicate if the command was accepted or not
    ###
    highlight: (type) ->
        if type == 'negative'
            @body.attr @styles.control.negative
        if type == 'positive'
            @body.attr @styles.control.positive
        setTimeout =>
            @setState()
        , 250

    ###
    sets this control state
    `state` is a object with properties:
    - TODO....
    ###
    setState: (state) ->
        @state = Object.assign {}, @state, state

        if @state.dev
            @advanceBtn.show()
            return

        switch @state.state
            when 'manual'
                if @state.listSize > 0
                    info = "#{@state.listSize}"
                else
                    info = ''
                @text.text("MANUAL\n#{info}").cx(@ops.x).cy(@ops.y).fill('white')
                box = @text.bbox()
                @body.attr({width:box.w + @ops.p*2 ,height:box.h + @ops.p})
                .center(box.cx, box.cy)
                .attr({fill:'#48f', stroke:'white'})

                @advanceBtn.hide()

            when 'auto'
                if @state.dest and @state.count
                    info = "#{@state.listSize} / #{@state.dest} -> #{@state.count}"
                else
                    info = "#{@state.listSize}"
                @text.text("AUTO\n#{info}").cx(@ops.x).cy(@ops.y).fill('white')
                box = @text.bbox()
                @body.attr({width:box.w + @ops.p*2 ,height:box.h + @ops.p})
                .center(box.cx, box.cy)
                .attr({fill:'#f64', stroke:'white'})

                @advanceBtn.hide()


            when 'remote'
                if @state.dest and @state.count
                    info = "#{@state.listSize} / #{@state.dest} -> #{@state.count}"
                else
                    info = "#{@state.listSize}"
                @text.text("REMOTE\n#{info}").cx(@ops.x).cy(@ops.y).fill('black')
                box = @text.bbox()
                @body.attr({width:box.w + @ops.p*2 ,height:box.h + @ops.p})
                .center(box.cx, box.cy)
                .attr({fill:'#fc4', stroke:'white'})

                @advanceBtn.show()
